import { Box, Flex, Text } from "@chakra-ui/react";
import CharityDarkIcon from "assets/svg/charity-dark-icon";
import CharityDashboardCard from "components/card/CharityDashboardCard";
import { useNavigate } from "react-router-dom";
import { IDashboardSummary } from "types/dashboard";
import { formatCurrency } from "utils";

interface CharityOverviewProps {
  loading: boolean;
  data: IDashboardSummary | null;
}

const CharityOverview = ({ loading, data }: CharityOverviewProps) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Flex gap={3} alignItems="center">
        <CharityDarkIcon />
        <Text color="#000" fontSize={24} fontWeight={700} lineHeight={"24px"}>
          Charity
        </Text>
      </Flex>
      <Box display={`flex`} gap={`2rem`}>
        <CharityDashboardCard
          text="Active charity"
          totalValue={data?.activeCharity as number}
          isLoading={loading}
          showSeeAll
          seeAll={() => navigate(`/main/charity`)}
        />
        <CharityDashboardCard
          text="Total amount raised (CAD)"
          totalValue={`$${formatCurrency(
            (data?.totalCharityRaised as number) ?? 0,
            true,
            false
          )}`}
          isLoading={loading}
          showSeeAll={false}
        />
      </Box>
    </Box>
  );
};

export default CharityOverview;
