import moment from "moment";
import { CountrySymbols } from "types/transactions";
import CanadaLogo from "assets/svg/countries/canada-logo.svg";
import BritishLogo from "assets/svg/countries/british-logo.svg";
import NigerianLogo from "assets/svg/countries/nigerian-logo.svg";
import USLogo from "assets/svg/countries/us-logo.svg";
import { AdminRole, IUser } from "types/user";
import { useEffect, useState } from "react";

export const handleReturnCountryCurrency = (countrySymbol: CountrySymbols) => {
  switch (countrySymbol) {
    case "USD":
      return "$";
    case "CAD":
      return "CA$";
    case "GBP":
      return "£";
    default:
      return "₦";
  }
};

export const handleReturnCountryCode = (
  phoneCode: string,
  countryOfResidence: string
) => {
  let currency;
  if (phoneCode === "+1" && countryOfResidence === "CA") {
    currency = "CAD";
  } else if (phoneCode === "+1" && countryOfResidence === "US") {
    currency = "USD";
  } else if (phoneCode === "+44") {
    currency = "GBP";
  } else {
    currency = "NGN";
  }
  return currency;
};

export const handleReturnPhoneNumber = (value: string) => {
  let phoneObj: { phone_code: string; phoneNumber: string } = {
    phone_code: "",
    phoneNumber: "",
  };

  switch (true) {
    case value.startsWith("+1"):
      phoneObj.phone_code = value.substring(0, 2);
      phoneObj.phoneNumber = value.slice(2);
      break;
    case value.startsWith("+44"):
      phoneObj.phone_code = value.substring(0, 3);
      phoneObj.phoneNumber = value.slice(3);
      break;

    default:
      phoneObj.phone_code = value.substring(0, 4);
      phoneObj.phoneNumber = value.slice(4);
  }

  return phoneObj;
};

export const formatCurrency = (
  num: number | string,
  space = true,
  shouldReturnCurrencyWithAmount = true,
  currencySymbol?: CountrySymbols | string
) => {
  const localNumber = typeof num === "string" ? Number(num) : num;

  const currency = shouldReturnCurrencyWithAmount
    ? handleReturnCountryCurrency(currencySymbol as CountrySymbols)
    : "";

  return Math.sign(localNumber || 0) === -1
    ? `-${currency} ${space ? " " : ""}${localNumber
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        .slice(1)}`
    : `${currency}${space ? " " : ""}${localNumber
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};

export const getUser = () => {
  let parsedUser, user;
  user = localStorage.getItem("user");
  if (user) {
    parsedUser = JSON.parse(user) as IUser;
  }

  return parsedUser;
};

export const getNestedValue = (obj: any, path: string) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export const renderTimeField = (text: string | number) => {
  return moment(text).format("MMM Do YY");
};

export const truncateMiddleText = (text: string, maxLength: number = 15) => {
  if (text.length <= maxLength) {
    return text;
  }

  var mid = Math.ceil(text.length / 2);
  var before = text.substring(0, mid - Math.ceil((maxLength - 3) / 2));
  var after = text.substring(mid + Math.floor((maxLength - 3) / 2));

  return before + "..." + after;
};

export const handleGetCountryLogo = (country: CountrySymbols) => {
  switch (country?.trim()) {
    case "USD":
      return USLogo;
    case "CAD":
      return CanadaLogo;
    case "GBP":
      return BritishLogo;
    case "EUR":
      return BritishLogo;
    default:
      return NigerianLogo;
  }
};

export const returnPhoneCode = (country: CountrySymbols) => {
  switch (country) {
    case "USD":
      return "+1";
    case "CAD":
      return "+1";
    case "GBP":
      return "+44";
    default:
      return "+234";
  }
};

export const isSuperUser = (user: AdminRole) => {
  switch (user) {
    case "advanced":
    case "super_admin":
      return true;
    default:
      return false;
  }
};

export const isJustSuperAdmin = (role: any) => {
  switch (role) {
    case "super_admin":
      return true;
    default:
      return false;
  }
}

export const useCheckPermission = (accountDetails: any, permitIds: number[]) => {
  const [permissionIds, setPermissionIds] = useState<number[]>([]);

  useEffect(() => {
    const ids = accountDetails?.admin?.permissions?.map((permission: any) => permission?.id) ?? [];
    setPermissionIds(ids);
  }, [accountDetails]);

  return permitIds?.some(permitId => permissionIds?.includes(permitId));
};

export const transformText = (value: string) => {
  if (value.includes("_")) {
    const [x, y] = value.split("_");
    return x + " " + y;
  }

  return value;
};

export function allCaps(text: string | number) {
  return String(text ?? '').toUpperCase()
}

export function allLower(text: string | number) {
  return String(text ?? '').toLowerCase()
}

export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
};
