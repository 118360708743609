import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type Props = {
  text: string;
  totalValue: number | string;
  viewUsersType?: string;
  isLoading: boolean;
};

const UsersCard = ({ text, totalValue, viewUsersType, isLoading }: Props) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (viewUsersType) {
      navigate(viewUsersType);
    }
  };

  return (
    <Card borderRadius={`.5rem`} size={`sm`} width={`15rem`}>
      <CardHeader
        display={`flex`}
        justifyContent={`space-between`}
        alignItems={`center`}
      >
        <Text fontSize="1rem" color="#595959" fontWeight={600}>
          {text}
        </Text>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          <Skeleton width="100px" height="20px" />
        ) : (
          <Text fontWeight={`bold`} fontSize={`1.4rem`}>
            {totalValue}
          </Text>
        )}
      </CardBody>
      <CardFooter>
        <Text
          cursor={"pointer"}
          onClick={handleNavigate}
          fontSize={`.8rem`}
          color={`#00A239`}
        >
          See more
        </Text>
      </CardFooter>
    </Card>
  );
};

export default UsersCard;
