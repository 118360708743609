/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import SideNav from "./SideNav";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const DashboardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");
  const currentPage = location.pathname.split("/")[2];
  const subRoute = location.pathname.split("/")[3];

  useEffect(() => {
    if (!token) {
      navigate("/auth/login");
    }
  }, [token]);

  return (
    <>
      <Flex flexDirection="row">
        <SideNav />
        <Box
          height={{ base: "calc(100% - 64px)", lg: "100vh" }}
          overflowY="auto"
          pb="30px"
          width={"100%"}
          bgColor="gray.50"
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={6}
            backgroundColor={"gray.50"}
            px={[10, 10, 20]}
            py={10}
            borderRadius={"15px"}
            width={"100%"}
            shadow="none"
          >
            <Breadcrumb separator={<ChevronRightIcon color="gray.200" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href="/main/dashboard"
                  fontSize={14}
                  color="#595959"
                >
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href={subRoute ? `/main/${currentPage}` : "#"}
                  textTransform="capitalize"
                  fontSize={14}
                  color="#595959"
                >
                  {currentPage === "dashboard" ? "" : currentPage}
                </BreadcrumbLink>
              </BreadcrumbItem>
              {subRoute ? (
                <BreadcrumbItem>
                  <BreadcrumbLink
                    href="#"
                    textTransform="capitalize"
                    fontSize={14}
                    color="#595959"
                  >
                    {subRoute}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              ) : null}
            </Breadcrumb>
            <Outlet />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default DashboardLayout;
