import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IOverviewExchangeRate } from "types/dashboard";
import UpdateExchangeRate from "components/Modal/UpdateExchangeRate";
import CurrencyPair from "components/CurrencyPair";
import UpdateFee from "components/Modal/UpdateFee";
import { IExchangeRatesSettings } from "types/exchangeRates";
import { CountrySymbols } from "types/transactions";
import { formatCurrency } from "utils";

type ExchangeCardProps = {
  data: IOverviewExchangeRate;
  settings: IExchangeRatesSettings | null;
  handleRefreshExchangeRate: () => void;
  canAccess: boolean;
  index: number;
};

const ExchangeCard = ({
  data,
  settings,
  canAccess,
  handleRefreshExchangeRate,
}: ExchangeCardProps) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditFeeModal, setShowEditFeeModal] = useState<boolean>(false);

  const handleGetFee = (currency: CountrySymbols) => {
    switch (currency) {
      case "CAD":
        return settings?.settings.exchange_fee_cad;
      default:
        return settings?.settings.exchange_fee_ngn;
    }
  };

  return (
    <Card size={`sm`} borderRadius={`.5rem`}>
      <CardHeader>
        <Box
          display={`flex`}
          justifyContent={`space-between`}
          alignItems={`center`}
        >
          <CurrencyPair
            fromCurrency={data?.fromCurrency}
            toCurrency={data?.toCurrency}
          />
          {canAccess ? (
            <Text
              onClick={() =>
                data?.fromCurrency === data?.toCurrency
                  ? setShowEditFeeModal(true)
                  : setShowModal(true)
              }
              color={`#00A239`}
              fontSize={`.8rem`}
              cursor="pointer"
            >
              Edit
            </Text>
          ) : null}
        </Box>
      </CardHeader>
      <CardBody>
        <Box display={`grid`} gridTemplateColumns={`repeat(2, 1fr)`}>
          <Box display={`flex`} flexDirection={`column`} gap={`.3rem`}>
            <Text fontSize={`.7rem`} color={`#9E9E9C`}>
              {data?.fromCurrency !== data?.toCurrency
                ? "Exchange Rate"
                : "Fee"}
            </Text>
            <Text fontSize={`1.5rem`} fontWeight={`bold`}>
              {data?.fromCurrency === data?.toCurrency
                ? handleGetFee(data.fromCurrency)
                : formatCurrency(data?.exchangeRate, false, false)}
            </Text>
          </Box>
        </Box>
      </CardBody>
      <CardFooter>
        <Text
          cursor={"pointer"}
          onClick={() => navigate("/main/exchange-rates")}
          fontSize={`.8rem`}
          color={`#00A239`}
        >
          See more
        </Text>
      </CardFooter>

      {showModal ? (
        <UpdateExchangeRate
          showModal={showModal}
          onClose={() => setShowModal(false)}
          data={data}
          handleRefresh={() => {
            setShowModal(false);
            handleRefreshExchangeRate();
          }}
        />
      ) : null}

      {showEditFeeModal && settings !== null ? (
        <UpdateFee
          showModal={showEditFeeModal}
          onClose={() => setShowEditFeeModal(false)}
          data={data}
          settings={settings}
          handleRefresh={() => {
            setShowEditFeeModal(false);
            handleRefreshExchangeRate();
          }}
        />
      ) : null}
    </Card>
  );
};

export default ExchangeCard;
