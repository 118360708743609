import * as Yup from "yup";
import toast from "react-hot-toast";
import { IErrorResponse } from "types/redux";
import { IUpdateExchangeRates } from "types/exchangeRates";
import { IOverviewExchangeRate } from "types/dashboard";
import { updateExchangeRates } from "features/main/exchangeRates/thunkActions";
import { useAppDispatch } from "app/hooks";

const UseUpdateExchangeRateModel = ({
  data,
  handleRefresh,
}: {
  data: IOverviewExchangeRate;
  handleRefresh: () => void;
}) => {
  const dispatch = useAppDispatch();

  const initialFormValues: IUpdateExchangeRates = {
    exchange_rate: data?.exchangeRate,
  };

  const updateExchangeRateSchema = Yup.object().shape({
    exchange_rate: Yup.string().required("Exchange rate is required"),
  });

  const handleUpdateExchangeRate = (
    payload: IUpdateExchangeRates,
    setSubmitting: (data: boolean) => void
  ) => {
    setSubmitting(true);

    const onSuccess = (message: string) => {
      if (message) {
        toast.success(message);
        handleRefresh();
      }
      setSubmitting(false);
    };

    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
      setSubmitting(false);
    };

    dispatch(
      updateExchangeRates({ payload, id: data.id, onSuccess, onFailure })
    );
  };

  return {
    initialFormValues,
    updateExchangeRateSchema,
    handleUpdateExchangeRate,
  };
};

export default UseUpdateExchangeRateModel;
