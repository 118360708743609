import {
  CardHeader,
  Card,
  Box,
  CardBody,
  SkeletonText,
} from "@chakra-ui/react";

const ExchangeRateLoadingCard = () => {
  return (
    <Card>
      <CardHeader>
        <Box display={`flex`} justifyContent={`space-between`} gap={`1rem`}>
          <SkeletonText noOfLines={1} width="40%" />
        </Box>
      </CardHeader>
      <CardBody>
        <SkeletonText noOfLines={2} width="30%" />
      </CardBody>
    </Card>
  );
};

export default ExchangeRateLoadingCard;
