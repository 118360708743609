import { Box, Flex, Text, SimpleGrid } from "@chakra-ui/react";
import RefreshIcon from "assets/svg/refresh-icon";
import ExchangeRateLoadingCard from "components/Loading/ExchangeRateLoadingCard";
import ExchangeCard from "components/card/ExchangeCard";
import UseExchangeRatesModel from "models/main/useExchangeRatesModel";
import { IExchangeRatesSettings } from "types/exchangeRates";
import { IProfile } from "types/profile";
import { AdminRole } from "types/user";
import { isSuperUser } from "utils";

const ExchangeRatesOverview = () => {
  const { isLoading, overviewRates, settings, handleRefreshExchangeRate } =
    UseExchangeRatesModel({
      shouldTakeFirstFour: true,
    });

  const user = localStorage.getItem("user");
  let parsedUser;
  if (user) {
    parsedUser = JSON.parse(user) as IProfile;
  }

  const canAccess = isSuperUser(parsedUser?.role as AdminRole);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Flex gap={3} alignItems="center">
        <RefreshIcon />
        <Text color="#000" fontSize={24} fontWeight={700} lineHeight={"24px"}>
          Exchange Rate
        </Text>
      </Flex>
      {isLoading ? (
        <SimpleGrid width="80%" columns={2} spacing={10}>
          {Array(4)
            .fill({})
            .map(() => (
              <ExchangeRateLoadingCard key={`${Math.random()}-loading-card`} />
            ))}
        </SimpleGrid>
      ) : (
        <SimpleGrid width="80%" columns={2} spacing={10}>
          {overviewRates.length > 0 &&
            overviewRates.map((overviewRate, index) => (
              <ExchangeCard
                index={index}
                canAccess={canAccess}
                data={overviewRate}
                key={`${Math.random()}-rate-${index}`}
                handleRefreshExchangeRate={handleRefreshExchangeRate}
                settings={settings as IExchangeRatesSettings}
              />
            ))}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default ExchangeRatesOverview;
