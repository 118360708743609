import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

interface IButtonProps extends ButtonProps {
  label: string;
  variant: "solid" | "outline";
  bgColor?: string;
  color?: string;
  size: "xs" | "sm" | "md" | "lg";
}

const ButtonComponent: React.FC<IButtonProps> = ({
  label,
  variant,
  color,
  size,
  bgColor = "primary",
  ...props
}) => {
  return (
    <Button
      bgColor={bgColor}
      color={`${color ? color : "#fff"}`}
      size={size}
      variant={variant}
      // _hover={
      //   bgColor === "primary"
      //     ? { bgColor: "primary", opacity: "0.8" }
      //     : { bgColor: "transparent", opacity: "0.9" }
      // }
      width={"100%"}
      boxShadow={bgColor === "secondary" ? "0px 16px 24px 0px #FFCD2C66" : ""}
      {...props}
    >
      {label}
    </Button>
  );
};

export default ButtonComponent;
