import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, IErrorResponse, ReduxAction } from "types/redux";
import { IAuditTrailData } from "types/audit";

interface IFetchAuditTrailResponse extends ApiResponse {
  data: IAuditTrailData;
}

interface IFetchAuditTrailArgs extends ReduxAction {
  page: number;
}

export const fetchAuditTrail = createAsyncThunk(
  "/audit/fetchAuditTrail",
  async ({ page, onFailure }: IFetchAuditTrailArgs, { rejectWithValue }) => {
    try {
      const { data }: IFetchAuditTrailResponse = await axios.get(
        `/audit-trails?page=${page}`
      );

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
