import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
} from "react";
import { Box, Text } from "@chakra-ui/react";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  value: string | any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  Icon?: JSX.Element;
  type?: HTMLInputTypeAttribute;
  textLength?: number;
  isRequired?: boolean;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
}

const TextInput = ({
  name,
  value,
  label,
  onChange,
  disabled,
  error,
  isRequired,
  leading,
  trailing,
  type = "text",
  ...props
}: TextInputProps) => {
  return (
    <div>
      <Box
        backgroundColor={error ? "#FBEDF3" : disabled ? "#F4F4F4" : "#F7F7F8"}
        border={`1px solid ${error ? "#E53E3E" : "#E2E8F0"}`}
        alignItems="center"
        position="relative"
        borderRadius="0.375rem"
        display="flex"
        gap="2px"
        width={"100%"}
        flexDirection="column"
      >
        <input
          className="input"
          data-peer
          placeholder=" "
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          onKeyPress={(event) => {
            if (type === "number") {
              if (!/^\d*(\.)?(\d{0,2})?$/.test(event.key)) {
                event.preventDefault();
              }
            }
          }}
          onMouseMove={(event) => {
            if (type === "number") {
              event.stopPropagation();
            }
          }}
          {...props}
        />
        {label ? (
          <label className="inputLabel">
            {label}
            <span>{isRequired && "*"}</span>
          </label>
        ) : null}
        {leading && <Box className="inputLeading">{leading}</Box>}
        {trailing && <Box className="inputTrailing">{trailing}</Box>}
      </Box>
      {error ? (
        <Text fontSize="12px" color={"red.500"} mt={1}>
          {error}
        </Text>
      ) : null}
    </div>
  );
};

export default TextInput;
