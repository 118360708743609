import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCharities, fetchCharityDonors } from "./thunkActions";
import { ICharity, ICharityDonorsList } from "types/charity";

interface InitialState {
  isLoading: boolean;
  charitiesList: ICharity | null;
  donorsList: ICharityDonorsList[];
}

const initialState: InitialState = {
  charitiesList: null,
  donorsList: [],
  isLoading: false,
};

const charitySlice = createSlice({
  name: "charity",
  initialState,
  reducers: {
    updateShowFullDescription: (state, action: PayloadAction<number>) => {
      const newCharitiesList = { ...state.charitiesList };
      newCharitiesList?.data?.forEach((charity) => {
        if (charity.id === action.payload) {
          charity.showFullDescription = !charity.showFullDescription;
        }
      });
      state.charitiesList = newCharitiesList as ICharity;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCharities.pending, (state) => {
        state.charitiesList = null;
        state.isLoading = true;
      })
      .addCase(fetchCharities.fulfilled, (state, action) => {
        const newCharitiesList = { ...action.payload };
        if (newCharitiesList.data.length) {
          newCharitiesList.data.forEach((a) => {
            a.showFullDescription = false;
          });
        }
        state.charitiesList = newCharitiesList;
        state.isLoading = false;
      })
      .addCase(fetchCharities.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchCharityDonors.pending, (state) => {
        state.donorsList = [];
        state.isLoading = true;
      })
      .addCase(fetchCharityDonors.fulfilled, (state, action) => {
        state.donorsList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCharityDonors.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default charitySlice.reducer;
export const { updateShowFullDescription } = charitySlice.actions;
export * from "./thunkActions";
