import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: "#00473C",
    bgColor: "#00473C",
    gray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#CDC6BB",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
    },
    bgDarkButton: "#030303",
    borderGray: "#E2E8F0",
    secondary: "#FFCD2C",
    dark: { 300: "#9E9E9C", 400: "#595959", 500: "#232323" },
    bgDark: "#303030",
    grayBg: "#E2E8F0",
    black: "#000000",
    badgeBg: "#606060",
    bgAvatar: "#6E72FF",
    green: { 100: "#C6F6D5", 500: "#00A239", 700: "#276749" },
    red: { 100: "#FED7D7", 500: "#E53E3E" },
    badgeBlue: "#EBECFF",
  },
  fonts: {
    body: "Inter, sans-serif",
  },
  styles: {
    global: {
      ".activeLink": {
        backgroundColor: "#FFCD2C",
        borderTopLeftRadius: "0.5rem",
        borderTopRightRadius: "0.5rem",
        width: "100%",
        height: "3px",
        display: "block",
      },
      ".inActiveLink": {
        backgroundColor: "#1128BB",
        borderTopLeftRadius: "40px",
        borderTopRightRadius: "40px",
        width: "100%",
        height: "3px",
        display: "block",
      },
      ".navlinks": {
        margin: "auto",
      },
      ".fileInput": {
        display: "none",
      },
      ".input": {
        border: "none",
        outline: "none",
        width: "100%",
        backgroundColor: "transparent",
        paddingTop: "20px",
        paddingBottom: "16px",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        caretColor: "#FFCD2C",
        color: "black",
      },
      ".inputLabel": {
        pointerEvents: "none",
        position: "absolute",
        left: "1rem",
        top: "4px",

        transition: "all",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
        transitionDuration: "150ms",
        color: "gray.400",
        fontSize: "16px",
        lineHeight: "2.8",
      },
      ".inputLabel[data-peer]:not(:placeholder-shown) + .input, .input:not(:placeholder-shown) + .inputLabel":
        {
          fontSize: ".9rem",
          lineHeight: "1",
          backgroundColor: "#fff",
          padding: "4px",
          top: "-10px",
        },
      ".input:focus + .inputLabel": {
        fontSize: "0.8rem",
        lineHeight: "1",
        backgroundColor: "#fff",
        padding: "4px",
        top: "-10px",
      },
      ".inputTrailing": {
        position: "absolute",
        right: "16px",
      },
      ".inputLeading": {
        position: "absolute",
        left: "16px",
      },
      ".inputLabel span": {
        color: "red",
        marginLeft: "2px",
      },
      ".options": {
        position: "absolute",
        zIndex: 10,
        backgroundColor: "white",
        padding: "5px",
        top: "4rem",
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        width: "100%",
        height: "auto",
        border: "1px solid #E2E8F0",
        color: "black",
      },
      "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":
        {
          WebkitBoxShadow: "0 0 0px 1000px rgba(0, 0, 0, 0, 0) inset",
          boxShadow: "0 0 0px 1000px rgba(0, 0, 0, 0, 0) inset",
          transition: "background-color 5000s ease-in-out 0s",
        },
      "input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button":
        {
          WebkitAppearance: "none",
          margin: "0",
        },
    },
  },

  breakpoints: {
    xxl: "1440px",
    xlg: "1200px",
    lg: "1025px",
    md: "769px",
    xs: "450px",
    xxs: "340px",
    base: "0px",
  },
});

export default theme;
