import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import {
  IExchangeRates,
  IExchangeRatesSettings,
  IUpdateExchangeRates,
} from "types/exchangeRates";

export interface IExchangeRatesResponse extends ApiResponse {
  data: IExchangeRates[];
}

export interface IExchangeRatesSettingsResponse extends ReduxAction {
  data: IExchangeRatesSettings;
}

export interface IUpdateExchangeRatesArgs extends ReduxAction {
  payload: IUpdateExchangeRates;
  id: number;
}

export interface IUpdateExchangeFeeArgs extends ReduxAction {
  payload: { [x: string]: string };
}

export const fetchExchangeRates = createAsyncThunk(
  "/exchangeRates/fetchExchangeRates",
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: IExchangeRatesResponse = await axios.get(
        "/exchange-rates"
      );

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const fetchExchangeRatesSettings = createAsyncThunk(
  "/exchangeRates/fetchExchangeRatesSettings",
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: IExchangeRatesSettingsResponse = await axios.get(
        "/settings"
      );

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const updateExchangeRates = createAsyncThunk(
  "/exchangeRates/updateExchangeRates",
  async (
    { payload, id, onSuccess, onFailure }: IUpdateExchangeRatesArgs,
    { rejectWithValue }
  ) => {
    try {
      const { message }: ApiResponse = await axios.put(
        `/exchange-rates/${id}`,
        payload
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const updateExchangeFee = createAsyncThunk(
  "/exchangeRates/updateExchangeFee",
  async (
    { payload, onSuccess, onFailure }: IUpdateExchangeFeeArgs,
    { rejectWithValue }
  ) => {
    try {
      const { message }: ApiResponse = await axios.post(
        `/edit-exchange-fee`,
        payload
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
