import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, IErrorResponse, ReduxAction } from "types/redux";
import { IAdminUserData, IInviteAdmin } from "types/admin";

interface IFetchAdminUsers extends ApiResponse {
  data: IAdminUserData;
}

interface IFetchAdminUsersArgs extends ReduxAction {
  page: number;
}

export interface IInviteAdminResponse extends ReduxAction {
  data: string;
}

export interface IInviteAdminArgs extends ReduxAction {
  payload: IInviteAdmin;
}

export const fetchAdminUsers = createAsyncThunk(
  "/admin/fetchAdminUsers",
  async ({ page, onFailure }: IFetchAdminUsersArgs, { rejectWithValue }) => {
    try {
      const { data }: IFetchAdminUsers = await axios.get("/adminlist", {
        params: { page },
      });

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const fetchPermissions = createAsyncThunk(
  "/admin/fetchPermissions",
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: any = await axios.get("/permissions");

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const fetchRoles = createAsyncThunk(
  "/admin/fetchRoles",
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: any = await axios.get("/roles");

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const inviteAdmin = createAsyncThunk(
  "/auth/inviteAdmin",
  async (
    { payload, onSuccess, onFailure }: IInviteAdminArgs,
    { rejectWithValue }
  ) => {
    try {
      const { data }: IInviteAdminResponse = await axios.post(
        `admin-user/invite`,
        payload
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
