import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Text,
  Skeleton,
} from "@chakra-ui/react";

type CharityDashboardCardProps = {
  text: string;
  totalValue: string | number;
  seeAll?: React.MouseEventHandler<HTMLParagraphElement>;
  showSeeAll: boolean;
  isLoading: boolean;
};

const CharityDashboardCard = ({
  text,
  totalValue,
  seeAll,
  showSeeAll,
  isLoading,
}: CharityDashboardCardProps) => {
  return (
    <Card borderRadius={`.5rem`} size={`sm`} width={`15rem`}>
      <CardHeader>
        <Text fontWeight={`bold`} color={`#595959`} fontSize={`.9rem`}>
          {text}
        </Text>
      </CardHeader>
      <CardBody
        display={`flex`}
        justifyContent={`space-between`}
        alignItems={`center`}
      >
        {isLoading ? (
          <Skeleton width="100px" height="20px" />
        ) : (
          <Text fontWeight={`bold`} fontSize={`1.4rem`}>
            {totalValue}
          </Text>
        )}
      </CardBody>
      <CardFooter>
        {showSeeAll ? (
          <Text
            fontSize={`.8rem`}
            color={`#00A239`}
            onClick={seeAll}
            cursor={`pointer`}
          >
            See all
          </Text>
        ) : null}
      </CardFooter>
    </Card>
  );
};

export default CharityDashboardCard;
