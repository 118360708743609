import { createSlice } from "@reduxjs/toolkit";
import { getCurrencies, toggleCurrency } from "./thunkActions";
import { ICurrency } from "types/transactions";

interface InitialState {
  isLoading: boolean;
  isToggling: boolean;
  currencies: ICurrency[];
}

const initialState: InitialState = {
  isLoading: false,
  isToggling: false,
  currencies: [],
};

export const currenciesSlice = createSlice({
  name: "currency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencies.pending, (state) => {
        state.currencies = [];
        state.isLoading = true;
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.currencies = action.payload;
        state.isLoading = false;
      })
      .addCase(getCurrencies.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(toggleCurrency.pending, (state) => {
        state.isToggling = true;
      })
      .addCase(toggleCurrency.fulfilled, (state, action) => {
        state.isToggling = false;
      })
      .addCase(toggleCurrency.rejected, (state) => {
        state.isToggling = false;
      });
  },
});

export default currenciesSlice.reducer;
export * from "./thunkActions";
