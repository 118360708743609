import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import CheckBoxIcon from "assets/svg/check-box";
import UseInviteNewAdminModel from "models/main/useInviteNewAdminModel";
import { useEffect, useRef, useState } from "react";

interface Permission {
  id: number;
  name: string;
  guard_name: string;
  role: string;
  created_at: string;
  updated_at: string;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  permissions?: Permission[];
  basicSelectedIds?: any[];
  setBasicSelectedIds?: any;
  setAdvSelectedIds?: any;
};

const BasicAdminModal = ({ isOpen, onClose, permissions, basicSelectedIds, setBasicSelectedIds, setAdvSelectedIds }: Props) => {
  const userRef = useRef(null);

  // const [dashBoardSummary, setDashBoardSummary] = useState(true);
  // const [depositTable, setDepositTable] = useState(false);
  // const [transactionTable, setTransactionTable] = useState(false);
  // const [userTable, setUserTable] = useState(false);
  // const [sanctionsTable, setSanctionTable] = useState(false);
  // const [transferTable, setTransferTable] = useState(false);
  // const [charity, setCharity] = useState(false);
  // const [compliance, setCompliance] = useState(false)
  // const [currencyTable, setCurrencyTable] = useState(false);
  // const [done, setDone] = useState(false);

  // const handleButtonClick = () => {
  //   setDone(true);
  // };

  const transformName = (name: string) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const basicClose = () => {
    onClose()
    setAdvSelectedIds([])
  }

  const handleCheckboxChange = (id: number, name: string) => {
    setBasicSelectedIds((prevSelectedIds: any) => {
      const updatedSet = new Set(prevSelectedIds);
      if (updatedSet.has(id)) {
        updatedSet.delete(id);
      } else {
        updatedSet.add(id);
      }
      return Array.from(updatedSet);
    });
  };

  useEffect(() => {
    const viewDashboardPermissionId = permissions?.find(permission => permission?.id === 1)?.id;
    if (viewDashboardPermissionId && !basicSelectedIds?.includes(viewDashboardPermissionId)) {
      setBasicSelectedIds((prevSelectedIds: any) => {
        const updatedSet = new Set([...prevSelectedIds, viewDashboardPermissionId]);
        return Array.from(updatedSet);
      });
    }
  }, [permissions, setBasicSelectedIds, basicSelectedIds]);

  return (
    <Modal
      size="xl"
      finalFocusRef={userRef}
      isOpen={isOpen}
      onClose={basicClose}
      isCentered={true}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          {/* {done ? (
            <Box
              display={`flex`}
              flexDirection={`column`}
              justifyContent={`center`}
              alignItems={`center`}
              gap={`1rem`}
              padding={`2rem`}
            >
              <CheckBoxIcon />
              <Text fontSize={`1.3rem`} fontWeight={`bold`}>
                Done
              </Text>
              <Text marginBottom={`2rem`}>
                An invitation has been sent to “john@investbamboo.com”
              </Text>
            </Box>
          ) : ( */}
            <Box>
              <Text
                fontSize={`1.3rem`}
                fontWeight={`bold`}
                marginBottom={`2rem`}
                marginTop={`2rem`}
              >
                Add new Admin - Basic
              </Text>
              <Box>
                <Text
                  fontSize={`1rem`}
                  fontWeight={`w500`}
                  marginBottom={`2rem`}
                >
                  Select access areas
                </Text>
                <Box
                  display={`grid`}
                  gridTemplateColumns={`repeat(2, 1fr)`}
                  marginBottom={`4rem`}
                  gap={`2rem`}
                >
                  {permissions?.map((permission: any) => (
                    <Checkbox
                      key={permission.id}
                      isChecked={basicSelectedIds?.includes(permission?.id)}
                      onChange={() => handleCheckboxChange(permission?.id, permission?.name)}
                      fontWeight="600"
                    >
                      {transformName(permission?.name)}
                    </Checkbox>
                  ))}
                  {/* <Checkbox
                    isChecked={dashBoardSummary}
                    onChange={() => setDashBoardSummary(!dashBoardSummary)}
                    fontWeight="600"
                  >
                    Dashboard Summary
                  </Checkbox>
                  <Checkbox
                    isChecked={depositTable}
                    onChange={() => setDepositTable(!depositTable)}
                    fontWeight="600"
                  >
                    Deposit Table
                  </Checkbox>
                  <Checkbox
                    isChecked={transactionTable}
                    onChange={() => setTransactionTable(!transactionTable)}
                    fontWeight="600"
                  >
                    Transactions Table
                  </Checkbox>
                  <Checkbox
                    isChecked={currencyTable}
                    onChange={() => setCurrencyTable(!currencyTable)}
                    fontWeight="600"
                  >
                    Currency Table
                  </Checkbox>
                  <Checkbox
                    isChecked={userTable}
                    onChange={() => setUserTable(!userTable)}
                    fontWeight="600"
                  >
                    User's Table
                  </Checkbox>
                  <Checkbox
                    isChecked={sanctionsTable}
                    onChange={() => setSanctionTable(!sanctionsTable)}
                    fontWeight="600"
                  >
                    Sanction Table
                  </Checkbox>
                  <Checkbox
                    isChecked={transferTable}
                    onChange={() => setTransferTable(!transferTable)}
                    fontWeight="600"
                  >
                    Transfer Table
                  </Checkbox>
                  <Checkbox
                    isChecked={charity}
                    onChange={() => setCharity(!charity)}
                    fontWeight="600"
                  >
                    Charity
                  </Checkbox>
                  <Checkbox
                    isChecked={compliance}
                    onChange={() => setCompliance(!compliance)}
                    fontWeight="600"
                  >
                    Compliance
                  </Checkbox> */}
                </Box>

                <Button
                  color={`#fff`}
                  backgroundColor={`#00473C`}
                  width={`100%`}
                  borderRadius={`.5rem`}
                  marginBottom={`1rem`}
                  onClick={basicClose}
                >
                  Add new admin
                </Button>
              </Box>
            </Box>
          {/* )} */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BasicAdminModal;
