import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExchangeRates, IExchangeRatesSettings } from "types/exchangeRates";
import {
  fetchExchangeRates,
  fetchExchangeRatesSettings,
} from "../exchangeRates/thunkActions";

interface InitialState {
  isLoading: boolean;
  isLoadingSettings: boolean;
  exchangeRates: IExchangeRates[];
  settings: IExchangeRatesSettings | null;
}

const initialState: InitialState = {
  exchangeRates: [],
  isLoading: false,
  settings: null,
  isLoadingSettings: false,
};

const exchangeRatesSlice = createSlice({
  name: "exchangeRates",
  initialState,
  reducers: {
    clearExchangeRates: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.exchangeRates = [];
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchExchangeRates.pending, (state) => {
        state.exchangeRates = [];
        state.isLoading = true;
      })
      .addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.exchangeRates = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchExchangeRates.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchExchangeRatesSettings.pending, (state) => {
        state.settings = null;
        state.isLoadingSettings = true;
      })
      .addCase(fetchExchangeRatesSettings.fulfilled, (state, action) => {
        state.settings = action.payload as IExchangeRatesSettings;
        state.isLoadingSettings = false;
      })
      .addCase(fetchExchangeRatesSettings.rejected, (state) => {
        state.isLoadingSettings = false;
      });
  },
});

export default exchangeRatesSlice.reducer;
export const { clearExchangeRates } = exchangeRatesSlice.actions;
export * from "./thunkActions";
