import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAuditTrail } from "./thunkActions";
import { IAuditTrailData } from "types/audit";

interface InitialState {
  auditTrail: IAuditTrailData | null;
  isLoading: boolean;
}

const initialState: InitialState = {
  auditTrail: null,
  isLoading: false,
};

const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    clearAuditTrails: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.auditTrail = null;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAuditTrail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAuditTrail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auditTrail = {
          ...action.payload,
          data: action.payload.data.length
            ? action.payload.data.filter((item) => item.guard === "admin")
            : [],
        };
      })
      .addCase(fetchAuditTrail.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default auditSlice.reducer;
export const { clearAuditTrails } = auditSlice.actions;
export * from "./thunkActions";
