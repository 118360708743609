import { Flex, Box } from "@chakra-ui/react";
import UsersOverview from "./components/UsersOverview";
import TransactionsOverview from "./components/TransactionsOverview";
import WalletsOverview from "./components/WalletsOverview";
import Sanctions from "./components/Sanctions";
import CharityOverview from "./components/CharityOverview";
import UseDashboardModel from "models/main/useDashboardModel";
import ExchangeRatesOverview from "./components/ExchangeRatesOverview";

const Dashboard = () => {
  const { isLoadingDashboard, dashboardSummary } = UseDashboardModel();

  return (
    <Flex gap={20} flexDirection="column" mt={6}>
      <ExchangeRatesOverview />
      <UsersOverview loading={isLoadingDashboard} data={dashboardSummary} />
      <TransactionsOverview
        loading={isLoadingDashboard}
        data={dashboardSummary}
      />
      <WalletsOverview loading={isLoadingDashboard} data={dashboardSummary} />
      <Flex gap={10}>
        <Box>
          <Sanctions loading={isLoadingDashboard} data={dashboardSummary} />
        </Box>
        <Box>
          <CharityOverview
            loading={isLoadingDashboard}
            data={dashboardSummary}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
