import { createSlice } from "@reduxjs/toolkit";
import { getAccountDetails, getProfile } from "./thunkActions";
import { IProfile } from "types/profile";

interface InitialState {
  isLoading: boolean;
  isUpdatingPassword: boolean;
  isAccLoading: boolean;
  profile: IProfile | null;
  accountDetails: any | null;
}

const initialState: InitialState = {
  isLoading: false,
  isAccLoading: false,
  isUpdatingPassword: false,
  profile: null,
  accountDetails: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.profile = null;
        state.isLoading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.isLoading = false;
      })
      .addCase(getProfile.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAccountDetails.pending, (state) => {
        state.accountDetails = null;
        state.isAccLoading = true;
      })
      .addCase(getAccountDetails.fulfilled, (state, action) => {
        state.accountDetails = action.payload;
        state.isAccLoading = false;
      })
      .addCase(getAccountDetails.rejected, (state) => {
        state.isAccLoading = false;
      })
  },
});

export default profileSlice.reducer;
export * from "./thunkActions";
