import DashboardLayout from "components/DashboardLayout";
import Dashboard from "pages/Dashboard";
import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const Login = lazy(() => import("pages/Login"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));

// Auth routes
const Users = lazy(() => import("pages/Users"));
const Transactions = lazy(() => import("pages/Transactions"));
const Charity = lazy(() => import("pages/Charity"));
const ExchangeRates = lazy(() => import("pages/ExchangeRates"));
const DepositTransactions = lazy(() => import("pages/DepositTransactions"));
const TransferTransactions = lazy(() => import("pages/TransferTransactions"));
const ViewDonors = lazy(() => import("pages/Charity/ViewDonors"));
const Sanctions = lazy(() => import("pages/Sanction"));
const Currency = lazy(() => import("pages/Currency"));
const Profile = lazy(() => import("pages/Profile"));
const AdminUsers = lazy(() => import("pages/AdminUsers"));
const Audit = lazy(() => import("pages/Audit"));

const RouteWrapper = (children: React.ReactNode | null) => (
  <Suspense fallback={<p>Loading...</p>}>{children}</Suspense>
);

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/main" element={RouteWrapper(<DashboardLayout />)}>
          <Route path="/main/dashboard" element={RouteWrapper(<Dashboard />)} />
          <Route path="/main/users" element={RouteWrapper(<Users />)} />
          <Route
            path="/main/transactions"
            element={RouteWrapper(<Transactions />)}
          />
          <Route path="/main/charity" element={RouteWrapper(<Charity />)} />
          <Route
            path="/main/deposits"
            element={RouteWrapper(<DepositTransactions />)}
          />
          <Route
            path="/main/transfers"
            element={RouteWrapper(<TransferTransactions />)}
          />
          <Route
            path="/main/charity/view-donors/:id"
            element={RouteWrapper(<ViewDonors />)}
          />

          <Route path="/main/profile" element={RouteWrapper(<Profile />)} />

          <Route
            path="/main/admin-users"
            element={RouteWrapper(<AdminUsers />)}
          />

          <Route path="/main/audit-trail" element={RouteWrapper(<Audit />)} />

          <Route
            path="/main/flagged-users"
            element={RouteWrapper(<Sanctions />)}
          />
          <Route
            path="/main/exchange-rates"
            element={RouteWrapper(<ExchangeRates />)}
          />
          <Route path="/main/currencies" element={RouteWrapper(<Currency />)} />
        </Route>

        <Route path="/auth">
          <Route path="/auth/login" element={RouteWrapper(<Login />)} />

          <Route
            path="/auth/forgot-password"
            element={RouteWrapper(<ForgotPassword />)}
          />

          <Route
            path="/auth/reset-password"
            element={RouteWrapper(<ResetPassword />)}
          />
        </Route>

        <Route path="*" element={<Navigate replace to="/auth/login" />} />
      </Routes>
    </BrowserRouter>
  );
};
export default AppRouter;
