import { configureStore } from "@reduxjs/toolkit";
import authReducer from "features/auth/slice";
import dashboardReducer from "features/main/dashboard/slice";
import userReducer from "features/main/users/slice";
import transactionsReducer from "features/main/transactions/slice";
import exxchangeRatesReducer from "features/main/exchangeRates/slice";
import charityReducer from "features/main/charity/slice";
import sanctionsReducer from "features/main/sanctions/slice";
import currenciesReducer from "features/main/currency/slice";
import profileReducer from "features/main/profile/slice";
import adminReducer from "features/main/admin/slice";
import auditReducer from "features/main/audit/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    users: userReducer,
    transactions: transactionsReducer,
    exchangeRates: exxchangeRatesReducer,
    charity: charityReducer,
    sanctions: sanctionsReducer,
    currency: currenciesReducer,
    profile: profileReducer,
    admin: adminReducer,
    audit: auditReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
