import { Box, Flex, Text } from "@chakra-ui/react";
import WalletIcon from "assets/svg/wallet-icon";
import WalletCard from "components/card/WalletCard";

// Logos
import NigerianLogo from "assets/svg/countries/nigerian-logo.svg";
import BritishLogo from "assets/svg/countries/british-logo.svg";
import CanadaLogo from "assets/svg/countries/canada-logo.svg";
import { IDashboardSummary } from "types/dashboard";
import { formatCurrency } from "utils";

interface WalletsOverviewProps {
  loading: boolean;
  data: IDashboardSummary | null;
}

const WalletsOverview = ({ loading, data }: WalletsOverviewProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Flex gap={3} alignItems="center">
        <WalletIcon />
        <Text color="#000" fontSize={24} fontWeight={700} lineHeight={"24px"}>
          Wallet Balances
        </Text>
      </Flex>
      <Box display={`flex`} gap={`2rem`}>
        <WalletCard
          icon={BritishLogo}
          title="British Pounds (£)"
          loading={loading}
          amount={
            data?.gbpTotalFundInwallet
              ? `GBP${formatCurrency(data?.gbpTotalFundInwallet, true, false)}`
              : "0"
          }
        />
        <WalletCard
          icon={CanadaLogo}
          title="Canadian Dollars (CA$)"
          loading={loading}
          amount={
            data?.cadTotalFundInwallet
              ? `CAD${formatCurrency(data?.cadTotalFundInwallet, true, false)}`
              : "0"
          }
        />
        <WalletCard
          icon={NigerianLogo}
          title="Nigerian Naira (₦)"
          loading={loading}
          amount={
            data?.ngnTotalFundInwallet
              ? `NGN${formatCurrency(data?.ngnTotalFundInwallet, true, false)}`
              : "0"
          }
        />
      </Box>
    </Box>
  );
};

export default WalletsOverview;
