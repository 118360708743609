import { Box, Flex, Text } from "@chakra-ui/react";
import UsersDarkIcon from "assets/svg/users-dark-icon";
import UsersCard from "components/card/UsersCard";
import { IDashboardSummary } from "types/dashboard";

interface UsersOverviewProps {
  loading: boolean;
  data: IDashboardSummary | null;
}

const UsersOverview = ({ loading, data }: UsersOverviewProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Flex gap={3} alignItems="center">
        <UsersDarkIcon />
        <Text color="#000" fontSize={24} fontWeight={700} lineHeight={"24px"}>
          Users
        </Text>
      </Flex>
      <Box display={`flex`} flexWrap={`wrap`} gap={`2rem`}>
        <UsersCard
          text="All Users"
          totalValue={data?.totalUsers || 0}
          isLoading={loading}
          viewUsersType={`/main/users`}
        />
        <UsersCard
          text="Approved Users"
          totalValue={data?.approvedUsers || 0}
          isLoading={loading}
          viewUsersType={`/main/users?status=approved`}
        />
        <UsersCard
          text="Pending reviews"
          totalValue={data?.pendingReviews || 0}
          isLoading={loading}
          viewUsersType={`/main/users?status=pending`}
        />
        <UsersCard
          text="Rejected"
          totalValue={data?.rejectedUsers || 0}
          isLoading={loading}
          viewUsersType={`/main/users?status=rejected`}
        />
      </Box>
    </Box>
  );
};

export default UsersOverview;
