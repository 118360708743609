import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Image, Text } from "@chakra-ui/react";
import { CountrySymbols } from "types/transactions";
import { handleGetCountryLogo } from "utils";

interface CurrencyPairProps {
  fromCurrency: CountrySymbols;
  toCurrency: CountrySymbols;
}

const CurrencyPair = ({ fromCurrency, toCurrency }: CurrencyPairProps) => {
  return (
    <Box display="flex" gap={1} alignItems="center">
      <Image
        src={`${handleGetCountryLogo(fromCurrency)}`}
        borderRadius="100%"
        height="16px"
        width="16px"
      />
      <Text color="#595959" fontWeight={600} fontSize={16}>
        {fromCurrency}
      </Text>

      <ArrowForwardIcon />
      <Image
        src={handleGetCountryLogo(toCurrency)}
        borderRadius="100%"
        height="16px"
        width="16px"
      />
      <Text color="#595959" fontWeight={600} fontSize={16}>
        {toCurrency}
      </Text>
    </Box>
  );
};

export default CurrencyPair;
