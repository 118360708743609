import { useRef } from "react";
import {
  Text,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { IOverviewExchangeRate } from "types/dashboard";
import { Formik } from "formik";
import ButtonComponent from "components/form/Button";
import UseUpdateCurrencyFeeModel from "models/main/useUpdateCurrencyFeeModel";
import { IExchangeRatesSettings } from "types/exchangeRates";
import { isSuperUser } from "utils";
import { AdminRole } from "types/user";
import { useAppSelector } from "app/hooks";

interface UpdateFeeProps {
  onClose: () => void;
  showModal: boolean;
  data: IOverviewExchangeRate;
  settings: IExchangeRatesSettings | null;
  handleRefresh: () => void;
}

const UpdateFee = ({
  showModal,
  onClose,
  data,
  handleRefresh,
  settings,
}: UpdateFeeProps) => {
  const userRef = useRef(null);
  const { initialFormValues, updateFeeKey, updateFeeSchema, handleUpdateFee } =
    UseUpdateCurrencyFeeModel({ data, settings, handleRefresh });

    const { accountDetails } = useAppSelector((state) => state.profile);

  return (
    <Modal
      size={`lg`}
      isOpen={showModal}
      onClose={onClose}
      isCentered={true}
      finalFocusRef={userRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
          <Text
            marginTop={`2rem`}
            fontSize={`1.2rem`}
            fontWeight={`bold`}
            textAlign={`center`}
          >
            Update {data?.fromCurrency} Fee
          </Text>
        </ModalHeader>

        <ModalBody padding="1rem 4.5rem 2rem 4.5rem">
          <Formik
            initialValues={initialFormValues}
            validationSchema={updateFeeSchema}
            onSubmit={(values, { setSubmitting }) =>
              handleUpdateFee(values, setSubmitting)
            }
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit,
            }) => (
              <Box display="flex" flexDirection="column" gap={4}>
                <Box width="100%">
                  <label
                    style={{
                      marginBottom: "5px",
                      fontWeight: "500",
                      color: "#9E9E9C",
                      lineHeight: "16px",
                    }}
                  >
                    Fee
                  </label>
                  <Box
                    mt={2}
                    border={`1px solid ${
                      errors[updateFeeKey] && touched[updateFeeKey]
                        ? "#E53E3E"
                        : "#232323"
                    }`}
                    backgroundColor={
                      errors[updateFeeKey] && touched[updateFeeKey]
                        ? "#FBEDF3"
                        : "tranparent"
                    }
                    borderRadius="6px"
                    padding="20px 16px"
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <Text fontWeight={700}>{data?.toCurrency}</Text>
                    <input
                      name={updateFeeKey}
                      value={values[updateFeeKey] || ""}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      style={{
                        border: "none",
                        outline: "none",
                        backgroundColor: "transparent",
                        width: "100%",
                      }}
                      onKeyPress={(event) => {
                        if (!/^\d*(\.)?(\d{0,2})?$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onMouseMove={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </Box>

                  {touched[updateFeeKey] && errors[updateFeeKey] ? (
                    <Text fontSize="12px" color={"red.500"} mt={1}>
                      {errors[updateFeeKey]}
                    </Text>
                  ) : null}
                </Box>
                {isSuperUser(accountDetails?.admin?.role as AdminRole) ? (
                <ButtonComponent
                  mt={2}
                  size={"lg"}
                  disabled={isSubmitting}
                  type="submit"
                  label="Save"
                  variant="solid"
                  isLoading={isSubmitting}
                  onClick={() => handleSubmit()}
                /> ) : null }
              </Box>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdateFee;
