import { Box, Flex, Text } from "@chakra-ui/react";
import WarningIcon from "assets/svg/warning-icon";
import SanctionsCard from "components/card/SanctionsCard";
import { IDashboardSummary } from "types/dashboard";

interface SanctionsProps {
  loading: boolean;
  data: IDashboardSummary | null;
}

const Sanctions = ({ loading, data }: SanctionsProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Flex gap={3} alignItems="center">
        <WarningIcon />
        <Text color="#000" fontSize={24} fontWeight={700} lineHeight={"24px"}>
          Sanctions
        </Text>
      </Flex>
      <Box>
        <SanctionsCard
          text="Flagged users"
          totalValue={data?.sanctions as number}
          isLoading={loading}
        />
      </Box>
    </Box>
  );
};

export default Sanctions;
