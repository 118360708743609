import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITransactions } from "types/transactions";
import { fetchTransactions } from "./thunkActions";

interface InitialState {
  isLoading: boolean;
  transactions: ITransactions | null;
}

const initialState: InitialState = {
  isLoading: false,
  transactions: null,
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    clearTransactions: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.transactions = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTransactions.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default transactionsSlice.reducer;
export const { clearTransactions } = transactionsSlice.actions;
export * from "./thunkActions";
