import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import {
  ICreateDeposit,
  ITransactions,
  ITransactionSearchParams,
  TransactionType,
} from "types/transactions";
import { IUser } from "types/user";
import { IDateRangePicker } from "types/picker";
import moment from "moment";

export interface ITransactionResponse extends ApiResponse {
  data: ITransactions;
}
export interface ITransactionsArgs extends ReduxAction {
  transactionType: TransactionType;
  page: number;
  queries: ITransactionSearchParams;
  dateQuerries: IDateRangePicker;
}

export interface IVerifyUserArgs extends ReduxAction {
  payload: { phone_number: string };
}

export interface ICreateManualDepositArgs extends ReduxAction {
  payload: ICreateDeposit;
  uploadedFile: File;
}

export interface IVerifyUserResponse extends ApiResponse {
  data: IUser;
}

export const fetchTransactions = createAsyncThunk(
  "/transactions/fetchTransactions",
  async (
    {
      page,
      transactionType,
      queries,
      dateQuerries,
      onFailure,
    }: ITransactionsArgs,
    { rejectWithValue }
  ) => {
    try {
      const searchParams = new URLSearchParams();

      if (Object.values(queries)?.length) {
        Object.entries(queries).forEach(([key, value]) => {
          if (value) {
            searchParams.append(`${key}`, value);
          }
        });
      }

      if (dateQuerries?.from_date && dateQuerries?.to_date) {
        // const startDate = moment.utc(dateQuerries?.from_date).format();
        // const endDate = moment.utc(dateQuerries?.to_date).format();

        const startDate = moment(dateQuerries?.from_date).format('YYYY-MM-DD');
        const endDate = moment(dateQuerries?.to_date).format('YYYY-MM-DD');

        searchParams.append("from_date", startDate);
        searchParams.append("to_date", endDate);
      }

      const { data }: ITransactionResponse = await axios.get(
        `/transactions/${transactionType}?page=${page}`,
        { params: searchParams }
      );
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const verifyUser = createAsyncThunk(
  "/transactions/verifyUser",
  async (
    { payload, onSuccess, onFailure }: IVerifyUserArgs,
    { rejectWithValue }
  ) => {
    try {
      const { data }: IVerifyUserResponse = await axios.post(
        `/transactions/verify-user`,
        payload
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const createManualDeposit = createAsyncThunk(
  "/transactions/createManualDeposit",
  async (
    { payload, uploadedFile, onSuccess, onFailure }: ICreateManualDepositArgs,
    { rejectWithValue }
  ) => {
    try {
      const newPayload = { ...payload };
      delete newPayload.proof_of_payment;
      const formData = new FormData();
      for (const [key, value] of Object.entries(newPayload)) {
        if (value) {
          formData.append(`${key}`, value as unknown as any);
        }
      }
      formData.append("proof_of_payment", uploadedFile);

      const { message }: ApiResponse = await axios.post(
        `/transactions/manual-deposit`,
        formData
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
