import { useRef, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Checkbox,
  Spinner,
} from "@chakra-ui/react";
import TextInput from "components/form/TextInput";
import UseInviteNewAdminModel from "models/main/useInviteNewAdminModel";
import { Formik } from "formik";
import ButtonComponent from "components/form/Button";
import CheckBoxIcon from "assets/svg/check-box";
import AdvancedModal from "./AdvancedModal";
import BasicAdminModal from "./BasicAdminModal";
import { useAppSelector } from "app/hooks";
import CustomSelect from "components/form/CustomSelect";
import { ObjectKeyValuePair } from "types/select";

type NewAdminModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const NewAdminModal = ({ isOpen, onClose }: NewAdminModalProps) => {
  const userRef = useRef(null);
  const { isFetchingPermissions, isFetchingRoles, permissions, roles } = useAppSelector((state) => state.admin);
  const {
    step,
    userEmail,
    invitationLevel,
    initialFormValues,
    inviteAdminSchema,
    handleInviteAdmin,
    basicCheck,
    advCheck,
    isOpenBasic,
    isOpenAdvanced,
    onCloseBasic,
    onCloseAdvanced,
    handleAdvCheck,
    handleBasicCheck,
    handleOtherAdminCheck,
    handleSetInvitationLevel,
  } = UseInviteNewAdminModel();

  
  const [basicSelectedIds, setBasicSelectedIds] = useState<number[]>([]);
  const [advSelectedIds, setAdvSelectedIds] = useState<number[]>([]);

  const basicRoles = permissions?.filter((e: any) => e?.role === 'basic');
  const advancedRoles = permissions?.filter((e: any) => e?.role === 'advanced');

  const rolesArray: ObjectKeyValuePair[] = [
    {label: "Basic", value: "basic"},
    {label: "Advanced", value: "advanced"},
    // {label: "Super Admin", value: "super_admin"},
  ];

  const getRole = (value: string | unknown) => {
    const found = rolesArray.find((y: any) => y.value === value);

    if (found) {
      const { label, value } = found;
      return { label, value } as ObjectKeyValuePair;
    }

    return { label: "Select Role", value: "" };
  };

  return (
    <>
      <Modal
        size="3xl"
        finalFocusRef={userRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {step === 1 ? (
            <ModalHeader>
              <Text marginTop={`2rem`} fontSize={`1.4rem`} fontWeight={`bold`}>
                Add new admin
              </Text>
            </ModalHeader>
          ) : null}
          <ModalBody p={step === 1 ? 10 : 5}>
            <>
              {isFetchingPermissions ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="300px"
                  padding={10}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#00473B"
                    size="xl"
                  />
                </Box>
              ) : (
                <>
                  {step === 1 ? (
                    <Formik
                      initialValues={initialFormValues}
                      validationSchema={inviteAdminSchema}
                      onSubmit={(values, { setSubmitting }) =>
                        handleInviteAdmin(values, setSubmitting, basicSelectedIds, advSelectedIds)
                      }
                    >
                      {({
                        handleChange,
                        values,
                        isSubmitting,
                        touched,
                        errors,
                        setFieldValue,
                        handleSubmit,
                      }) => (
                        <>
                          <Box
                            display={`grid`}
                            gridTemplateColumns={`repeat(2, 1fr)`}
                            gap={`1rem`}
                          >
                            <TextInput
                              label="First Name"
                              name="firstName"
                              onChange={handleChange}
                              disabled={isSubmitting}
                              value={values.firstName ?? ""}
                              error={
                                touched.firstName && errors.firstName
                                  ? errors.firstName
                                  : undefined
                              }
                            />
                            <TextInput
                              label="Last Name"
                              name="lastName"
                              onChange={handleChange}
                              disabled={isSubmitting}
                              value={values.lastName ?? ""}
                              error={
                                touched.lastName && errors.lastName
                                  ? errors.lastName
                                  : undefined
                              }
                            />
                            <TextInput
                              label="Work Email"
                              name="email"
                              onChange={handleChange}
                              disabled={isSubmitting}
                              value={values.email ?? ""}
                              error={
                                touched.email && errors.email
                                  ? errors.email
                                  : undefined
                              }
                            />
                            <TextInput
                              label="Role/Title"
                              name="designation"
                              onChange={handleChange}
                              disabled={isSubmitting}
                              value={values.designation ?? ""}
                              error={
                                touched.designation && errors.designation
                                  ? errors.designation
                                  : undefined
                              }
                            />
                            {/* <CustomSelect
                              label="Role/Title"
                              name="role"
                              customHeight="60px"
                              options={rolesArray}
                              shouldBeDisabled={isSubmitting}
                              handleOnChange={(value: string | number) => {
                                setFieldValue("role", value as string);
                                if(value === 'basic') {
                                  handleBasicCheck()
                                } else if (value === 'advanced') {
                                  handleAdvCheck()
                                } else {
                                  handleOtherAdminCheck()
                                }
                              }}
                              value={getRole(values.role)}
                              error={
                                touched.role && errors.role
                                  ? errors.role
                                  : undefined
                              }
                            /> */}
                          </Box>
                          <Text
                            fontSize={`1rem`}
                            fontWeight={`bold`}
                            marginTop={`3rem`}
                            marginBottom={`1.5rem`}
                          >
                            Select access level
                          </Text>

                          <Box
                            display={`grid`}
                            gridTemplateColumns={`repeat(2, 1fr)`}
                            gap={`2rem`}
                            marginBottom={`3rem`}
                          >
                            <Box
                              display={`flex`}
                              flexDirection={`column`}
                              gap={`1rem`}
                            >
                              <Checkbox
                                isChecked={basicCheck}
                                onChange={() =>
                                  // handleSetInvitationLevel("isBasic", true)
                                  handleBasicCheck()
                                }
                                fontWeight="600"
                              >
                                Basic
                              </Checkbox>
                              <Text
                                paddingLeft={`1.5rem`}
                                paddingRight={`1rem`}
                                fontSize={`14px`}
                                color={`#181716`}
                              >
                                Can view details only but not update users’
                                status, export data nor carry out actions.{" "}
                              </Text>
                            </Box>

                            <Box
                              display={`flex`}
                              flexDirection={`column`}
                              gap={`1rem`}
                            >
                              <Checkbox
                                isChecked={advCheck}
                                onChange={() =>
                                  // handleSetInvitationLevel("isAdvanced", true)
                                  handleAdvCheck()
                                }
                                fontWeight="600"
                              >
                                Advanced
                              </Checkbox>
                              <Text
                                paddingLeft={`1.5rem`}
                                paddingRight={`1rem`}
                                fontSize={`14px`}
                                color={`#181716`}
                              >
                                Advanced Can view details, update users’ status,
                                export data and carry out actions.{" "}
                              </Text>
                            </Box>
                          </Box>
                          <ButtonComponent
                            mt={6}
                            size={"lg"}
                            // disabled={isSubmitting}
                            isDisabled={isSubmitting || (basicCheck === false && advCheck === false)}
                            type="submit"
                            label="Proceed"
                            variant="solid"
                            isLoading={isSubmitting}
                            onClick={() => handleSubmit()}
                          />
                        </>
                      )}
                    </Formik>
                  ) : null}

                  {step === 2 ? (
                    <Box
                      display={`flex`}
                      flexDirection={`column`}
                      justifyContent={`center`}
                      alignItems={`center`}
                      gap={`1rem`}
                      padding={`2rem`}
                    >
                      <CheckBoxIcon />
                      <Text fontSize={`1.3rem`} fontWeight={`bold`}>
                        Done
                      </Text>
                      <Text marginBottom={`2rem`} fontSize={'18px'}>
                        An invitation has been sent to 
                        {/* <br /> */}
                        {" "}<strong>"{userEmail}"</strong>
                      </Text>

                      <ButtonComponent
                          mt={6}
                          size={"lg"}
                          label="Done"
                          variant="solid"
                          onClick={onClose}
                        />
                    </Box>
                  ) : null}
                </>
              )}
            </>
          </ModalBody>
        </ModalContent>
      </Modal>

      {isOpenBasic ? (
        <BasicAdminModal
          isOpen={isOpenBasic}
          permissions={basicRoles}
          onClose={onCloseBasic}
          basicSelectedIds={basicSelectedIds}
          setBasicSelectedIds={setBasicSelectedIds}
          setAdvSelectedIds={setAdvSelectedIds}
          // onClose={() => handleSetInvitationLevel("isBasic", false)}
        />
    ) : null}

      {isOpenAdvanced ? (
        <AdvancedModal
          isOpen={isOpenAdvanced}
          permissions={advancedRoles}
          onClose={onCloseAdvanced}
          advSelectedIds={advSelectedIds}
          setAdvSelectedIds={setAdvSelectedIds}
          setBasicSelectedIds={setBasicSelectedIds}
          // onClose={() => handleSetInvitationLevel("isAdvanced", false)}
        />
      ) : null}
    </>
  );
};

export default NewAdminModal;
