import DashboardIcon from "assets/svg/navigation/dashboard-icon.svg";
import UserIcon from "assets/svg/navigation/user-icon.svg";
import TransactionsIcon from "assets/svg/navigation/transactions-icon.svg";
import ComplianceIcon from "assets/svg/navigation/compliance-icon.svg";
import CharityIcon from "assets/svg/navigation/charity-icon.svg";
import ExchangeRatesIcon from "assets/svg/navigation/exchange-rates-icon.svg";
import { INavigationData } from "types/navigation";
import { IProfile } from "types/profile";
import { isSuperUser } from "utils";
import { AdminRole } from "types/user";

const user = localStorage.getItem("user");
let parsedUser;
if (user) {
  parsedUser = JSON.parse(user) as IProfile;
}

const data: INavigationData[] = [
  {
    text: "Dashboard",
    icon: DashboardIcon,
    link: "/main/dashboard",
  },
  {
    text: "Users",
    icon: UserIcon,
    link: "/main/users",
  },
  {
    text: "Transactions",
    icon: TransactionsIcon,
    link: "/main/transactions",
  },
  {
    text: "Exchange Rates",
    icon: ExchangeRatesIcon,
    link: "/main/exchange-rates",
  },
  {
    text: "Currencies",
    icon: ExchangeRatesIcon,
    link: "/main/currencies",
  },

  {
    text: "Compliance",
    icon: ComplianceIcon,
    link: "/main/flagged-users",
  },
  {
    text: "Charity",
    icon: CharityIcon,
    link: "/main/charity",
  },
];

if (isSuperUser(parsedUser?.role as AdminRole)) {
  data.push({
    text: "Audit Trail",
    icon: ComplianceIcon,
    link: "/main/audit-trail",
  });
}

export const navData = data;
