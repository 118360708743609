/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch } from "app/hooks";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { IInviteAdmin } from "types/admin";
import { IErrorResponse } from "types/redux";
import { useEffect, useState } from "react";
import {
  fetchPermissions,
  fetchRoles,
  inviteAdmin,
} from "features/main/admin/thunkActions";
import { ObjectKeyValuePair } from "types/select";
import { useDisclosure } from "@chakra-ui/react";

type InvitationLevel = "isBasic" | "isAdvanced";

const selectOptions: ObjectKeyValuePair[] = [
  {
    label: "Basic",
    value: "basic",
  },
  {
    label: "Advanced",
    value: "advanced",
  },
];

const UseInviteNewAdminModel = () => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<number>(1);

  const { isOpen: isOpenBasic, onOpen: onOpenBasic, onClose: onCloseBasic } = useDisclosure()
  const { isOpen: isOpenAdvanced, onOpen: onOpenAdvanced, onClose: onCloseAdvanced } = useDisclosure()

  const [userEmail, setUserEmail] = useState<string>("");
  const [invitationLevel, setInvitationLevel] = useState<{
    isBasic: boolean;
    isAdvanced: boolean;
  }>({
    isBasic: false,
    isAdvanced: false,
  });

  const [showInviteAdminModal, setShowInviteAdminModal] = useState<boolean>(false);

  const [basicCheck, setBasicCheck] = useState(false);
  const [advCheck, setAdvCheck] = useState(false);
  const [role, setRole] = useState("")

  const initialFormValues: IInviteAdmin = {
    firstName: "",
    lastName: "",
    email: "",
    // role: "",
    status: "",
    designation: "",
  };

  const inviteAdminSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .min(5, "Must be longer than 5 characters")
      .required("Email is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    // role: Yup.string().required("Role is required"),
    designation: Yup.string().required("Designation is required"),
  });

  const handleSetInvitationLevel = (type: InvitationLevel, value: boolean) => {
    setInvitationLevel((prev) => ({ ...prev, [type]: value }));
  };

  const handleBasicCheck = () => {
    setRole("basic")
    setAdvCheck(false)
    setBasicCheck(true)
    onOpenBasic()
  }

  const handleAdvCheck = () => {
    setRole("advanced")
    setBasicCheck(false)
    setAdvCheck(true)
    onOpenAdvanced()
  }

  const handleOtherAdminCheck = () => {
    setBasicCheck(false)
    setAdvCheck(false)
  }

  const handleGetRole = (value: string) => {
    const newOptions = [...selectOptions];

    const x = newOptions.find((a) => a.value === value);

    if (x) {
      return { label: x.label, value: x.value } as ObjectKeyValuePair;
    }

    return { label: "Select a role", value: "" } as ObjectKeyValuePair;
  };

  const handleInviteAdmin = (
    payload: IInviteAdmin,
    setSubmitting: (data: boolean) => void,
    basicSelectedIds: any[],
    advSelectedIds: any[],
  ) => {
    const newPayload = { ...payload };
    setUserEmail(newPayload.email);
    newPayload.name = `${newPayload.firstName} ${newPayload.lastName}`;
    newPayload.role = role;
    newPayload.permissions = [...basicSelectedIds, ...advSelectedIds];
    delete newPayload.firstName;
    delete newPayload.lastName;
    setSubmitting(true);

    const onSuccess = (data: string) => {
      if (data) {
        setSubmitting(false);
        setStep(2);
      }
    };

    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
      setSubmitting(false);
    };

    dispatch(inviteAdmin({ payload: newPayload, onSuccess, onFailure }));
  };

  const handleFetchPermissions = () => {
    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
    };

    dispatch(fetchPermissions({ onFailure }));
  };

  const handleFetchRoles = () => {
    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
    };

    dispatch(fetchRoles({ onFailure }));
  };

  useEffect(() => {
    handleFetchPermissions();
    handleFetchRoles();
  }, [dispatch]);

  return {
    step,
    userEmail,
    role, 
    setRole,
    selectOptions,
    invitationLevel,
    inviteAdminSchema,
    initialFormValues,
    handleInviteAdmin,
    handleSetInvitationLevel,
    handleGetRole,
    basicCheck,
    advCheck,
    isOpenBasic,
    isOpenAdvanced,
    onCloseBasic,
    onCloseAdvanced,
    handleAdvCheck,
    handleBasicCheck,
    handleOtherAdminCheck,
    showInviteAdminModal,
    setShowInviteAdminModal
  };
};

export default UseInviteNewAdminModel;
