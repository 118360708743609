import { createSlice } from "@reduxjs/toolkit";
import { fetchDashboardSummary } from "./thunkActions";
import { IDashboardSummary } from "types/dashboard";

interface InitialState {
  isLoadingDashboard: boolean;
  dashboardSummary: IDashboardSummary | null;
}

const initialState: InitialState = {
  isLoadingDashboard: false,

  dashboardSummary: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardSummary.pending, (state) => {
        state.isLoadingDashboard = true;
      })
      .addCase(fetchDashboardSummary.fulfilled, (state, action) => {
        state.dashboardSummary = action.payload;
        state.isLoadingDashboard = false;
      })
      .addCase(fetchDashboardSummary.rejected, (state) => {
        state.isLoadingDashboard = false;
      });
  },
});

export default dashboardSlice.reducer;
export * from "./thunkActions";
