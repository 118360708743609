import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchSingleUser, fetchUsers } from "./thunkActions";
import { IUserDetails, IUsers } from "types/user";

interface InitialState {
  isLoading: boolean;
  users: IUsers | null;
  singleUser: IUserDetails | null;
  isFetchingUserDetails: boolean;
}

const initialState: InitialState = {
  isLoading: false,
  isFetchingUserDetails: false,
  users: null,
  singleUser: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsersData: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.users = null;
      }
    },
    clearUser: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.singleUser = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(fetchSingleUser.pending, (state) => {
        state.isFetchingUserDetails = true;
      })
      .addCase(fetchSingleUser.fulfilled, (state, action) => {
        state.singleUser = action.payload;
        state.isFetchingUserDetails = false;
      })
      .addCase(fetchSingleUser.rejected, (state) => {
        state.isFetchingUserDetails = false;
      });
  },
});

export default usersSlice.reducer;
export const { clearUsersData, clearUser } = usersSlice.actions;
export * from "./thunkActions";
