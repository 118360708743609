import { createSlice } from "@reduxjs/toolkit";
import { fetchAdminUsers, fetchPermissions, fetchRoles } from "./thunkActions";
import { IAdminUserData } from "types/admin";

interface InitialState {
  adminUsers: IAdminUserData | null;
  isLoading: boolean;
  isFetchingPermissions: boolean;
  isFetchingRoles: boolean;
  permissions: any;
  roles: any;
}

const initialState: InitialState = {
  adminUsers: null,
  isLoading: false,
  isFetchingPermissions: false,
  isFetchingRoles: false,
  permissions: [],
  roles: []
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAdminUsers.pending, (state) => {
        state.adminUsers = null;
        state.isLoading = true;
      })
      .addCase(fetchAdminUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.adminUsers = action.payload;
      })
      .addCase(fetchAdminUsers.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.permissions = [];
        state.isFetchingPermissions = true;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.isFetchingPermissions = false;
        state.permissions = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state) => {
        state.isFetchingPermissions = false;
      })
      .addCase(fetchRoles.pending, (state) => {
        state.roles = [];
        state.isFetchingRoles = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.isFetchingRoles = false;
        state.roles = action.payload;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.isFetchingRoles = false;
      });
  },
});

export default adminSlice.reducer;
export * from "./thunkActions";
