import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import {
  IForgotPassword,
  ILogin,
  ILoginResponseData,
  IResetPassword,
} from "types/auth";

export interface ILoginArgs extends ReduxAction {
  loginPayload: ILogin;
}

interface IResetPasswordArgs extends ReduxAction {
  payload: IResetPassword;
  token: string;
}

interface IForgotPasswordArgs extends ReduxAction {
  payload: IForgotPassword;
}

export interface ILoginResponse extends ApiResponse {
  data: ILoginResponseData;
}

export const login = createAsyncThunk(
  "/auth/login",
  async (
    { loginPayload, onSuccess, onFailure }: ILoginArgs,
    { rejectWithValue }
  ) => {
    try {
      const { data }: ILoginResponse = await axios.post(
        "/admin-login",
        loginPayload
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const createPassword = createAsyncThunk(
  "/auth/create-password",
  async (
    { payload, token, onSuccess, onFailure }: IResetPasswordArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: ApiResponse = await axios.post(
        `/invite/${token}`,
        payload
      );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const resetPassword = createAsyncThunk(
  "/auth/reset-password",
  async (
    { payload, token, onSuccess, onFailure }: IResetPasswordArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: ApiResponse = await axios.post(
        `/set-password/${token}`,
        payload
      );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const sendResetLink = createAsyncThunk(
  "/auth/sendResetLink",
  async (
    { payload, onSuccess, onFailure }: IForgotPasswordArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: ApiResponse = await axios.post(
        `/reset-password`,
        payload
      );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
