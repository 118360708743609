import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import { IFlaggedUser, IFlaggedUsers, IUserSearchParams } from "types/user";

export interface IFetchFlaggedUsersArgs extends ReduxAction {
  page: number;
  queries: IUserSearchParams;
}

export interface IFlaggedUsersResponse extends ApiResponse {
  data: IFlaggedUsers;
}

export interface IFetchSingleFlaggedUserResponse extends ApiResponse {
  data: IFlaggedUser;
}

export interface IFetchSingleUserArgs extends ReduxAction {
  id: number;
}

export const fetchFlaggedUsers = createAsyncThunk(
  "/sanctions/fetchFlaggedUsers",
  async (
    { page, queries, onFailure }: IFetchFlaggedUsersArgs,
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams();

    if (Object.values(queries)?.length) {
      Object.entries(queries).forEach(([key, value]) => {
        if (value) {
          searchParams.append(`${key}`, value);
        }
      });
    }

    try {
      const { data }: IFlaggedUsersResponse = await axios.get(
        `/users/aml/flagged?page=${page}`,
        { params: searchParams }
      );

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const fetchSingleFlaggedUser = createAsyncThunk(
  "/sanctions/fetchSingleFlaggedUser",
  async ({ id, onFailure }: IFetchSingleUserArgs, { rejectWithValue }) => {
    try {
      const { data }: IFetchSingleFlaggedUserResponse = await axios.get(
        `/user/${id}/aml-verification`
      );

      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
