import {
  Card,
  CardBody,
  CardHeader,
  Text,
  Image,
  Skeleton,
} from "@chakra-ui/react";

interface WalletCardProps {
  title: string;
  icon: string;
  amount: string;
  loading: boolean;
}

const WalletCard = ({ title, amount, icon, loading }: WalletCardProps) => {
  return (
    <Card borderRadius={`.5rem`} size={`sm`} width={`20rem`}>
      <CardHeader display={`flex`} alignItems={`center`} gap={2}>
        <Image borderRadius="100%" src={icon} alt="country-icon" />
        <Text fontSize="1rem" color="#595959" fontWeight={600}>
          {title}
        </Text>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Skeleton width="100px" height="20px" />
        ) : (
          <Text fontWeight={`bold`} fontSize={`1.4rem`}>
            {amount}
          </Text>
        )}
      </CardBody>
    </Card>
  );
};

export default WalletCard;
