import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { fetchDashboardSummary } from "features/main/dashboard/thunkActions";
import toast from "react-hot-toast";
import { IErrorResponse } from "types/redux";

const UseDashboardModel = () => {
  const dispatch = useAppDispatch();
  const { isLoadingDashboard, dashboardSummary } = useAppSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
    };

    dispatch(fetchDashboardSummary({ onFailure }));
  }, [dispatch]);

  return { isLoadingDashboard, dashboardSummary };
};

export default UseDashboardModel;
