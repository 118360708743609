import * as Yup from "yup";
import toast from "react-hot-toast";
import { IErrorResponse } from "types/redux";
import { IExchangeRatesSettings } from "types/exchangeRates";
import { IOverviewExchangeRate } from "types/dashboard";
import { updateExchangeFee } from "features/main/exchangeRates/thunkActions";
import { useAppDispatch } from "app/hooks";

type InitialFormValues = { [x: string]: string };

const UseUpdateCurrencyFeeModel = ({
  data,
  settings,
  handleRefresh,
}: {
  data: IOverviewExchangeRate;
  handleRefresh: () => void;
  settings: IExchangeRatesSettings | null;
}) => {
  const dispatch = useAppDispatch();

  const updateFeeKey = `exchange_fee_${data?.fromCurrency?.toLowerCase()}`;

  const initialFormValues: InitialFormValues = {
    [updateFeeKey]:
      data.fromCurrency === "CAD"
        ? (settings?.settings.exchange_fee_cad as string)
        : (settings?.settings.exchange_fee_ngn as string),
  };

  const updateFeeSchema = Yup.object().shape({
    [updateFeeKey]: Yup.string().required(`Fee is required`),
  });

  const handleUpdateFee = (
    payload: InitialFormValues,
    setSubmitting: (data: boolean) => void
  ) => {
    setSubmitting(true);

    const onSuccess = (message: string) => {
      if (message) {
        toast.success(message);
        handleRefresh();
      }
      setSubmitting(false);
    };

    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
      setSubmitting(false);
    };

    dispatch(updateExchangeFee({ payload, onSuccess, onFailure }));
  };

  return {
    updateFeeKey,
    initialFormValues,
    updateFeeSchema,
    handleUpdateFee,
  };
};

export default UseUpdateCurrencyFeeModel;
