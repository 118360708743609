/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  clearExchangeRates,
  fetchExchangeRates,
  fetchExchangeRatesSettings,
} from "features/main/exchangeRates/slice";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IOverviewExchangeRate } from "types/dashboard";
import { IErrorResponse } from "types/redux";

const UseExchangeRatesModel = ({
  shouldTakeFirstFour,
}: {
  shouldTakeFirstFour?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { isLoading, exchangeRates, settings } = useAppSelector(
    (state) => state.exchangeRates
  );
  const [overviewRates, setOverviewRates] = useState<IOverviewExchangeRate[]>(
    []
  );

  const handleFetchExchangeRates = () => {
    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
    };

    dispatch(fetchExchangeRates({ onFailure }));
  };

  useEffect(() => {
    handleFetchExchangeRates();

    return () => {
      dispatch(clearExchangeRates(true));
    };
  }, [dispatch]);

  const handleRefreshExchangeRate = () => {
    handleFetchExchangeRates();
    handleFetchSettings();
  };

  const handleFetchSettings = () => {
    const onFailure = (errorResponse: IErrorResponse) => {
      toast.error(errorResponse.message);
    };

    dispatch(fetchExchangeRatesSettings({ onFailure }));
  };

  useEffect(() => {
    if (exchangeRates.length && shouldTakeFirstFour) {
      const newExchangeRates = exchangeRates.slice(0, 4);
      const newDashboardRates: IOverviewExchangeRate[] = newExchangeRates.map(
        (x) =>
          ({
            fromCurrency: x?.currency_pair_code?.slice(0, 3),
            toCurrency: x?.currency_pair_code?.slice(3),
            exchangeRate: x?.exchange_rate,
            id: x?.id,
          } as IOverviewExchangeRate)
      );

      setOverviewRates(newDashboardRates);
    }
  }, [exchangeRates]);

  useEffect(() => {
    handleFetchSettings();
  }, []);

  return {
    isLoading,
    exchangeRates,
    settings,
    overviewRates,
    handleRefreshExchangeRate,
  };
};
export default UseExchangeRatesModel;
