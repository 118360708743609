import { Provider } from "react-redux";
import { store } from "./app/store";
import { ChakraBaseProvider } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";

import theme from "./theme";
import AppRouter from "components/AppRouter";

function App() {
  return (
    <Provider store={store}>
      <ChakraBaseProvider theme={theme}>
        <AppRouter />
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 5000,
          }}
        />
      </ChakraBaseProvider>
    </Provider>
  );
}

export default App;
