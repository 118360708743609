import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import { IChangePassword, IProfile } from "types/profile";

export interface IGetProfileResponse extends ApiResponse {
  data: IProfile;
}

interface IChangePasswordArgs extends ReduxAction {
  payload: IChangePassword;
}

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: IGetProfileResponse = await axios.get("/profile");
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const getAccountDetails = createAsyncThunk(
  "profile/getAccountDetails",
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: IGetProfileResponse = await axios.get("/admin/account-details");
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const changePassword = createAsyncThunk(
  "profile/changePassword",
  async (
    { payload, onSuccess, onFailure }: IChangePasswordArgs,
    { rejectWithValue }
  ) => {
    try {
      const { message }: ApiResponse = await axios.post(
        `/change-password`,
        payload
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
