import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import {
  ICurrency,
  IUpdateCurrency,
  IUpdateCurrencyLimit,
} from "types/transactions";

export interface IGetCurrencyResponse extends ApiResponse {
  data: ICurrency[];
}

interface IToggleCurrency extends ReduxAction {
  id: number;
  payload: IUpdateCurrency;
}

interface IUpdateLimit extends ReduxAction {
  data: IUpdateCurrencyLimit;
}

export const getCurrencies = createAsyncThunk(
  "currency/getCurrencies",
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: IGetCurrencyResponse = await axios.get("/currencies");
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const toggleCurrency = createAsyncThunk(
  "currency/toggleCurrency",
  async (
    { id, payload, onSuccess, onFailure }: IToggleCurrency,
    { rejectWithValue }
  ) => {
    try {
      const { message }: ApiResponse = await axios.patch(
        `/currencies/${id}/status`,
        payload
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);

export const updateCurrencyLimit = createAsyncThunk(
  "currency/updateCurrencyLimit",
  async ({ data, onSuccess, onFailure }: IUpdateLimit, { rejectWithValue }) => {
    const { currencyId, updateKey, amountInValue } = data;

    try {
      const { message }: ApiResponse = await axios.put(
        `currencies/${currencyId}`,
        {
          [updateKey]: amountInValue,
        }
      );
      if (onSuccess) onSuccess(message);
      return message;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
