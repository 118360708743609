import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/http";
import { ApiResponse, ReduxAction, IErrorResponse } from "types/redux";
import { IDashboardSummary } from "types/dashboard";

export interface IDashboardSummaryResponse extends ApiResponse {
  data: IDashboardSummary;
}

export const fetchDashboardSummary = createAsyncThunk(
  "/main/fetchDashboardSummary",
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: IDashboardSummaryResponse = await axios.get("/dashboard");
      return data;
    } catch (error) {
      if (onFailure) onFailure(error as IErrorResponse);
      return rejectWithValue({ error });
    }
  }
);
