import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Text,
  Skeleton,
  Box,
} from "@chakra-ui/react";
import React from "react";

type TransactionsCardProps = {
  text: string;
  seeMore: React.MouseEventHandler<HTMLParagraphElement>;
  isLoading: boolean;
  children: React.ReactNode;
};

const TransactionsCard = ({
  text,
  seeMore,
  isLoading,
  children,
}: TransactionsCardProps) => {
  return (
    <Card borderRadius={`.5rem`} size="sm" width="20rem">
      <CardHeader
        display={`flex`}
        justifyContent={`space-between`}
        alignItems={`center`}
      >
        <Text fontSize="1rem" color="#595959" fontWeight={600}>
          {text}
        </Text>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          <Skeleton width="100px" height="20px" />
        ) : (
          <Box>{children}</Box>
        )}
      </CardBody>
      <CardFooter>
        <Text
          cursor={"pointer"}
          onClick={seeMore}
          fontSize={`.8rem`}
          color={`#00A239`}
        >
          See more
        </Text>
      </CardFooter>
    </Card>
  );
};

export default TransactionsCard;
