import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchFlaggedUsers, fetchSingleFlaggedUser } from "./thunkActions";
import { IFlaggedUser, IFlaggedUsers } from "types/user";

interface InitialState {
  isLoading: boolean;
  isFetchingSingleFlaggedUser: boolean;
  flaggedUsers: IFlaggedUsers | null;
  singleFlaggedUser: IFlaggedUser | null;
}

const initialState: InitialState = {
  isLoading: false,
  isFetchingSingleFlaggedUser: false,
  flaggedUsers: null,
  singleFlaggedUser: null,
};

export const sanctionsSlice = createSlice({
  name: "sanctions",
  initialState,
  reducers: {
    clearUsersData: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.flaggedUsers = null;
        state.singleFlaggedUser = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlaggedUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFlaggedUsers.fulfilled, (state, action) => {
        state.flaggedUsers = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFlaggedUsers.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchSingleFlaggedUser.pending, (state) => {
        state.isFetchingSingleFlaggedUser = true;
      })
      .addCase(fetchSingleFlaggedUser.fulfilled, (state, action) => {
        state.singleFlaggedUser = action.payload;
        state.isFetchingSingleFlaggedUser = false;
      })
      .addCase(fetchSingleFlaggedUser.rejected, (state) => {
        state.isFetchingSingleFlaggedUser = false;
      });
  },
});

export default sanctionsSlice.reducer;
export const { clearUsersData } = sanctionsSlice.actions;
export * from "./thunkActions";
