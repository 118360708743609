import { Box, Flex, Text } from "@chakra-ui/react";
import TransactionsDarkIcon from "assets/svg/transactions-dark-icon";
import TransactionsCard from "components/card/TransactionsCard";
import { useNavigate } from "react-router-dom";
import { IDashboardSummary } from "types/dashboard";
import { formatCurrency } from "utils";

interface TransactionsOverviewProps {
  loading: boolean;
  data: IDashboardSummary | null;
}

const TransactionsOverview = ({ loading, data }: TransactionsOverviewProps) => {
  const navigate = useNavigate();

  const moveToDeposit = () => {
    navigate(`/main/deposits`);
  };
  const moveToTransfers = () => {
    navigate(`/main/transfers`);
  };

  const TransactionCardDetails = ({
    CADAmount,
    NGNAmount,
    GBPAmount,
  }: {
    CADAmount: number;
    NGNAmount: number;
    GBPAmount: number;
  }) => (
    <Box display="flex" flexDirection="column" gap={1}>
      <Text fontWeight={700} fontSize="1.4rem">
        CAD {`${formatCurrency((CADAmount as number) || 0, false, false)}`}
      </Text>
      <Text fontWeight={700} fontSize="1.4rem">
        NGN {`${formatCurrency((NGNAmount as number) || 0, false, false)}`}
      </Text>
      <Text fontWeight={700} fontSize="1.4rem">
        GBP {`${formatCurrency((GBPAmount as number) || 0, false, false)}`}
      </Text>
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Flex gap={3} alignItems="center">
        <TransactionsDarkIcon />
        <Text color="#000" fontSize={24} fontWeight={700} lineHeight={"24px"}>
          Transactions
        </Text>
      </Flex>
      <Box display={`flex`} flexWrap={`wrap`} gap={`2rem`}>
        <TransactionsCard
          text="All Deposits"
          isLoading={loading}
          seeMore={moveToDeposit}
          children={
            <TransactionCardDetails
              CADAmount={data?.depositsCAD as number}
              NGNAmount={data?.depositsNGN as number}
              GBPAmount={data?.depositsGBP as number}
            />
          }
        />
        <TransactionsCard
          text="All Transfers"
          isLoading={loading}
          seeMore={moveToTransfers}
          children={
            <TransactionCardDetails
              CADAmount={data?.transfersCAD as number}
              NGNAmount={data?.transfersNGN as number}
              GBPAmount={data?.transfersGBP as number}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default TransactionsOverview;
