import {
  Card,
  CardBody,
  CardHeader,
  Text,
  Skeleton,
  CardFooter,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type SanctionsCardProps = {
  text: string;
  totalValue: number | string;
  isLoading: boolean;
};

const SanctionsCard = ({ text, totalValue, isLoading }: SanctionsCardProps) => {
  const navigate = useNavigate();

  const moveToSanctionsPage = () => {
    navigate(`/main/flagged-users`);
  };

  return (
    <Card borderRadius={`.5rem`} size={`sm`} width={`15rem`}>
      <CardHeader>
        <Text fontWeight={`bold`} color={`#595959`} fontSize={`.9rem`}>
          {text}
        </Text>
      </CardHeader>
      <CardBody
        display={`flex`}
        justifyContent={`space-between`}
        alignItems={`center`}
      >
        {isLoading ? (
          <Skeleton width="100px" height="20px" />
        ) : (
          <Text fontWeight={`bold`} fontSize={`1.4rem`}>
            {totalValue}
          </Text>
        )}
      </CardBody>
      <CardFooter>
        <Text
          onClick={moveToSanctionsPage}
          cursor={`pointer`}
          fontSize={`.8rem`}
          color={`#00A239`}
        >
          See all
        </Text>
      </CardFooter>
    </Card>
  );
};

export default SanctionsCard;
